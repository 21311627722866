.topLogo {
    height: 24px;
    margin: 16px 16px 16px 16px;
    display: block;
}

.header {
    padding: 0; 
    //background: #ffffff;
    //border-bottom: 1px solid #e8e8e8;
}

.icon {
    margin-right: 10px;
}

.leftMenu {
    float: left;
    height: 64px;
    line-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: #001529;
}

.rightMenu {
    float: right;
}

.topMenu {
    float: right;
}

.content {
    background-color: #ffffff;
    padding: 0px;
    margin: 0px;
    min-height: 100vh;
    min-width: 300px;
    margin-Top: 61px;
}

:global .ant-menu.ant-menu-dark .ant-menu-item-selected.noBackground {
    background-color: transparent; /*Overriden property*/
  }