.footer {
    background-color: #222;
    color: #ffffff;
}

.footerSection {
    justify-content: center;
}

.sectionHeader {
    color: #ffffff;
    text-align: center;
}

.logo {
    height: 16px;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    align-self: center;
}